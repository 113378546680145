import React, { useState, useEffect } from "react";
import CommonForm from "../../../components/commonform/commonForm";
import CustomIcons from "../../../components/icons/kIcons.component";
import { spaceParkAdvisorsList } from "../public.api";
import Loader from "../../../components/loader/loader";

import "./style.css";

export default function List(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const fetchEvents = () => {
    spaceParkAdvisorsList().then((res) => {
      setData(res.data);
      // setUrl(res.current_url);
      setIsActive(false);
    });
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  return (
    <div className="proj">
      <Loader open={isActive} />
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "സ്പേസ്പാർക്ക് ഉപദേശകർ"
              : "Spacepark Advisors"}
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="container adbox mt-3 ">
          <>
            {/* {data?.filter((x) => x.is_executive_council_member === 0)?.length >
              0 && (
              <div className="hedp">Governing body of Kerala Spacepark</div>
            )} */}

            <div className="row">
              {data?.map((item) => (
                <>
                  {item.is_executive_council_member === 0 &&
                    item.is_head_position === 1 && (
                      <div className="col-md-12  d-flex justify-content-center">
                        <div className="adviser-cards-container ">
                          <center>
                            <div className="card tile">
                              <div className="card-body adv-bg">
                                <img
                                  src={`${result}/${item.photo}`}
                                  alt=""
                                  className="adviser_image chief"
                                />
                                <h6>
                                  <b>{item.name}</b>
                                </h6>
                                <p>{item.designation}</p>
                                <small>{item.email}</small>
                              </div>
                            </div>
                          </center>
                        </div>
                      </div>
                    )}
                </>
              ))}
            </div>
            <div className="container">
              <div className="row justify-content-center">
                {data?.map(
                  (item) =>
                    item.is_head_position === 0 && item.is_executive_council_member === 0 && item?.super_admin_status === 4 && (
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-4" key={item.id}>
                        <div
                          className="p-3 h-100"
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "20px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            // minHeight: "180px",
                            // minHeight : "100% !important"
                          }}
                        >

                          <div className="row align-items-center">
                            <div className="col-4 d-flex justify-content-start" >
                              <img
                                src={`${result}/${item.photo}`}
                                alt=""
                                className="img-fluid "
                                style={{ height: "150px", width : "150px",objectFit: "fill", backgroundColor : "#eeeeee" }}
                              />
                            </div>
                            <div className="col-8 text-start text-md-left">
                              <h6>
                                <b>{item.name}</b>
                              </h6>
                              <p className="mb-1">{item.designation}</p>
                              <small className="text-muted">{item.email}</small>
                            </div>
                          </div>

                          {/* Domains Section (Full Width) */}
                          <div className="mt-3 ">
                            {/* <b>Domains: </b> */}
                            {item.domain_types?.length > 0 ? (
                              item.domain_types.map((domain, index) => (
                                <span key={domain.id} className="text-primary p-1 m-2 bg-[#eeeee]">
                                  #{domain.domain_name}
                                  {index < item.domain_types.length - 1 ? ", " : ""}
                                </span>
                              ))
                            ) : (
                              <span>No Domain</span>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>



          </>

          {/* ---------------------------------------------------- */}
          {data?.filter((x) => x.is_executive_council_member === 1)?.length >
            0 && (
              <div className="hedp">Executive Council of Kerala Spacepark</div>
            )}

          <div className="row">
            {data?.map((item) => (
              <>
                {item.is_executive_council_member === 1 &&
                  item.is_head_position === 1 && (
                    <div className="col-md-12  d-flex justify-content-center">
                      <div className="adviser-cards-container">
                        <center>
                          <div className="card tile">
                            <div className="card-body adv-bg">
                              <img
                                src={`${result}/${item.photo}`}
                                alt=""
                                className="adviser_image chief"
                              />
                              <h6>
                                <b>{item.name}</b>
                              </h6>
                              <p>{item.designation}</p>
                              <small>{item.email}</small>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
          <div className="row justify-content-center">
            {data?.map((item) => (
              <>
                {item.is_head_position === 0 &&
                  item.is_executive_council_member === 1 && (
                    <div className="col-12 col-md-3">
                      <div className="adviser-cards-container">
                        <center>
                          <div className="card tile sub">
                            <div className="card-body adv-bg">
                              <img
                                src={`${result}/${item.photo}`}
                                alt=""
                                className="adviser_image"
                              />
                              <h6>
                                <b>{item.name}</b>
                              </h6>
                              <p>{item.designation}</p>
                              <small>{item.email}</small>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="container adbox mt-3 ">


          <div
            className="d-flex justify-content-center align-items-center w-100 py-5"
            style={{ fontSize: "18px" }}
          >
            No Advisors Found
          </div>
        </div>
      )}
    </div>
  );
}
